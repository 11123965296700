import { jsx as _jsx, jsxs as _jsxs } from "preact/jsx-runtime";
import { Link } from "react-router-dom";
function StepNavigation(props) {
    const { prevLink, nextLink, prevDisabled, nextDisabled, children, onClickPrev, onClickNext } = props;
    if (!prevLink && !nextLink && !children) {
        return null;
    }
    return (_jsxs("div", { className: "form__actions", children: [prevLink && (_jsx(Link, { to: prevLink, onClick: onClickPrev, className: `btn btn--prev btn--align-center${prevDisabled ? " disabled" : ""}`, children: "Back" })), nextLink && (_jsx(Link, { to: nextLink, onClick: onClickNext, className: `btn btn--next btn--align-center${nextDisabled ? " disabled" : ""}`, children: "Next" })), children] }));
}
;
export default StepNavigation;
